export default  [
    {hp:7,
     speed:4,
     damage:1.2,
     startFrame:0
    },
    {hp:1,
        speed:6,
        damage:1.6,
        startFrame:3
       },
    {hp:6,
     speed:3,
     damage:2,
     startFrame:6
    
    },
    {hp:10,
        speed:3,
        damage:1,
        startFrame:9
       }]