export default [
  {
    name: "Wave 1",
    subtitle: "Watch for Mines",
    level: 1,
    spawnTimer: 2500,
    enemies: [0, 100, 0, 0, 0],
    length: 20000,
  },
  {
    name: "Wave 2",
    subtitle: "Here come the scouts",
    level: 2,
    spawnTimer: 2500,
    enemies: [45, 100, 0, 0, 0],
    length: 25000,
  },
  {
    name: "Wave 3",
    subtitle: "Be on watch",
    level: 3,
    spawnTimer: 2500,
    enemies: [50, 100, 0, 0, 0],
    length: 30000,
  },
  {
    name: "Wave 4",
    subtitle: "Counter Force",
    level: 3,
    spawnTimer: 1500,
    enemies: [100, 0, 0, 0, 0],
    length: 35000,
  },
  {
    name: "Wave 5",
    subtitle: "Bring in the Carrier Ship",
    level: 3,
    spawnTimer: 2500,
    enemies: [25, 50, 0, 100, 0],
    length: 30000,
  },
  {
    name: "Wave 6",
    subtitle: "Mine Sweeper",
    level: 3,
    spawnTimer: 500,
    enemies: [0, 100, 0, 0, 0],
    length: 10000,
  },
  {
    name: "Wave 7",
    subtitle: "Full Force",
    level: 3,
    spawnTimer: 2000,
    enemies: [25, 75, 0, 100, 0],
    length: 30000,
  },
  {
    name: "Wave 8",
    subtitle: "I'm getting dizzy",
    level: 3,
    spawnTimer: 2000,
    enemies: [50, 0, 75, 100, 0],
    length: 30000,
  },
  {
    name: "Wave 9",
    subtitle: "We must stop them",
    level: 3,
    spawnTimer: 1300,
    enemies: [25, 50, 75, 100, 0],
    length: 30000,
  },
  {
    name: "Wave 10",
    subtitle: "Oh no, the boss is here",
    level: 3,
    spawnTimer: 2000,
    enemies: [0, 0, 0, 0, 100],
    length: 0,
  },
];
